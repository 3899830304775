import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import {
    ArrayContractStatus,
    CachedCurrentUserService,
    CurrentUser,
    UnifiedArray,
    ServiceCatalogQuote,
    SupportContractRenewalStatus,
} from '@pure1/data';
import { take } from 'rxjs/operators';

import { ArraysManager } from '../../services/arrays-manager.service';
import { ISearchParameters } from '../../ui/components/performance-table/performance-table.component';
import moment from 'moment/moment';

@Component({
    selector: 'appliance-expired-template',
    templateUrl: 'appliance-expired-template.component.html',
})
export class ApplianceExpiredTemplateComponent implements OnChanges, OnInit {
    @Input() readonly selectedArray: UnifiedArray;
    @Input() readonly analyticsHeader: string;
    @Input() readonly supportIconSize: number;
    @Input() readonly renewingIconSize: number;
    @Input() readonly selectedArrayExistingQuote: ServiceCatalogQuote;

    readonly ArrayContractStatusExpired = ArrayContractStatus.EXPIRED;
    readonly analyticsUIPrefix = 'appliances_arrays_';
    readonly today = moment.utc();

    analyticsAction: string;
    currentUser: CurrentUser;
    loading = false;

    constructor(
        private cachedCurrentUserService: CachedCurrentUserService,
        private arraysManager: ArraysManager,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.cachedCurrentUserService
            .get()
            .pipe(take(1))
            .subscribe(currentUser => {
                this.currentUser = currentUser;
            });

        this.arraysManager.fireGAEventForCustomerSeenExpiredEvergreenCardView(this.analyticsAction);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.analyticsHeader) {
            // The naming will be: Appliances - Summary Card(Expanded Card/Table) - appliances_arrays_request-renewal
            this.analyticsAction = this.analyticsHeader + ' - ' + this.analyticsUIPrefix;
        }
    }

    shouldShowContactSalesMessage(): boolean {
        //When the order move to Quote Accepted status
        //Or if the renewal opportunity is not ready
        const isQuoteAccepted = !!this.selectedArrayExistingQuote?.stages.find(stage => stage.name === 'Quote Accepted')
            ?.category;
        return (
            isQuoteAccepted ||
            !this.selectedArray?.contract_renewal_status ||
            this.selectedArray?.contract_renewal_status === SupportContractRenewalStatus.NONRENEWABLE
        );
    }

    requestSupportContractRenewal(): void {
        const filterToEvergreenPage: ISearchParameters[] = [];
        filterToEvergreenPage.push({ entity: 'arrays', key: 'array_name', value: this.selectedArray.name });
        this.router.navigate(['/fleet/subscriptions/contracts'], {
            queryParams: { filter: JSON.stringify(filterToEvergreenPage) },
        });
    }

    isExpiredArrayWithFutureSupportExpirationDate(): boolean {
        //Dealing with the bad data situation: expired array with future support expiration date
        return (
            this.selectedArray?.contract_status === ArrayContractStatus.EXPIRED &&
            this.selectedArray?.support_expiration_date?.isAfter(this.today)
        );
    }
}
