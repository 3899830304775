<!-- We use the the capacity card base for both capcon recs and capacity because they look similar -->
<div
    *ngIf="
        relatedRecommendations.leadingRecommendation.isCapacityRecommendation() ||
        relatedRecommendations.leadingRecommendation.isCapConRecommendation()
    "
    class="rec-card"
>
    <button
        *ngIf="isDismissEnabled"
        type="button"
        class="close modal-x-button"
        (click)="clickDismiss($event)"
        ngbTooltip="Dismiss this recommendation"
    >
        <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="12"></pureui-svg>
    </button>
    <div class="icon-spacing">
        <pureui-svg svg="icon-capacity-rec.svg" class="rec-symbol" height="24"></pureui-svg>
        <pureui-svg
            *ngIf="relatedRecommendations.leadingRecommendation.hasControllerAdditionalInformation()"
            svg="icon-controller-rec.svg"
            class="rec-symbol"
            height="24"
        ></pureui-svg>
    </div>
    <div class="rec-array-name"> {{ getEntityName() }} </div>
    <div class="description">{{ getCapacityProblem() }}</div>
    <div class="line-container">
        <recommendation-sparkline [data]="getSparkline()"></recommendation-sparkline>
        <div class="curr-percent" [style.top.px]="getCurrOffset()"> {{ getCurrentPercent() }} </div>
        <div class="threshold-percent" [style.top.px]="getProjectedOffset()">{{ getProjectedPercent() }}</div>
        <div class="vertical-now-line"></div>
        <div class="triangle-bottom"></div>
        <div class="now-container">
            <div class="now-text">Now</div>
        </div>
        <div class="in-days-container">
            <div class="in-days-text">{{ getTimerangeText() }}</div>
        </div>
    </div>

    <div class="below-icon-box" [ngClass]="{ 'human-icon': relatedRecommendations.leadingRecommendation.isSeRec() }">
        <pureui-svg
            [svg]="getSourceIcon()"
            height="13"
            [ngClass]="{ 'human-icon': relatedRecommendations.leadingRecommendation.isSeRec() }"
        ></pureui-svg>
        <!-- pureui-svg size is wonky, so add sized box for tooltip -->
        <div class="tooltip-box" [ngbTooltip]="getRecSourceTooltip()"></div>
    </div>
    <div class="recommendation-message">
        {{ getRecBlackText() }} <span class="simulation-text">{{ getRecGreenText() }}</span>
    </div>
    <div
        class="recommendation-message"
        *ngIf="relatedRecommendations.leadingRecommendation.hasControllerAdditionalInformation()"
    >
        {{ getControllerRecBlackText() }} <span class="simulation-text">{{ getControllerRecGreenText() }}</span>
    </div>
</div>

<div *ngIf="relatedRecommendations.leadingRecommendation.isControllerRecommendation()" class="rec-card">
    <button
        *ngIf="isDismissEnabled"
        type="button"
        class="close modal-x-button"
        (click)="clickDismiss($event)"
        ngbTooltip="Dismiss this recommendation"
    >
        <!-- pureui-svg size is wonky, so add sized box for tooltip -->
        <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="12"></pureui-svg>
    </button>
    <div class="icon-spacing"
        ><pureui-svg svg="icon-controller-rec.svg" class="rec-symbol" height="24"></pureui-svg
    ></div>
    <div class="rec-array-name"> {{ getEntityName() }} </div>
    <div class="rec-explanation">{{ getControllerMessage() }}</div>
    <model-bezel [displayModel]="getDisplayRecModel()"></model-bezel>

    <div
        class="below-icon-box controller"
        [ngClass]="{
            'human-icon': relatedRecommendations.leadingRecommendation.isSeRec(),
            'for-fa-xl-recommendation': getDisplayRecModel()?.toLowerCase().startsWith('//xl'),
        }"
    >
        <pureui-svg
            [svg]="getSourceIcon()"
            height="13"
            [ngClass]="{ 'human-icon': relatedRecommendations.leadingRecommendation.isSeRec() }"
        ></pureui-svg>
        <!-- pureui-svg size is wonky, so add sized box for tooltip -->
        <div class="tooltip-box" [ngbTooltip]="getRecSourceTooltip()"></div>
    </div>
    <div
        >{{ getControllerRecBlackText() }} <span class="simulation-text">{{ getControllerRecGreenText() }}</span></div
    >
</div>

<div *ngIf="relatedRecommendations.leadingRecommendation.isEOLRecommendation()" class="rec-card eol-card">
    <div class="request-quote-tag">Request Quote</div>
    <button
        *ngIf="isDismissEnabled"
        type="button"
        class="close modal-x-button"
        (click)="clickDismiss($event)"
        ngbTooltip="Dismiss this recommendation"
    >
        <!-- pureui-svg size is wonky, so add sized box for tooltip -->
        <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="12"></pureui-svg>
    </button>
    <div class="icon-spacing">
        <pureui-svg svg="eol-icon-small.svg" class="rec-symbol" height="24"></pureui-svg>
        <pureui-svg
            *ngIf="relatedRecommendations.getEoscRecommendation() != null"
            svg="end-of-support-contract.svg"
            class="rec-symbol"
            height="24"
        ></pureui-svg>
    </div>
    <div class="rec-array-name"> {{ getEntityName() }} </div>
    <div class="rec-explanation">
        @if (
            relatedRecommendations.leadingRecommendation.isEOLRecommendation() &&
            relatedRecommendations.getEoscRecommendation() != null
        ) {
            Your Evergreen//Foundation subscription's appliance has an
            <span class="red-text">expired support contract</span>
            and its component is approaching end of life
        } @else if (
            relatedRecommendations.leadingRecommendation.isM2XRecommendation() ||
            relatedRecommendations.leadingRecommendation.isEoLCombinedRecommendation()
        ) {
            An appliance in your Evergreen//Foundation subscription is approaching the end of life
        } @else if (
            relatedRecommendations.leadingRecommendation.is6GRecommendation() ||
            relatedRecommendations.leadingRecommendation.isEoL11Recommendation()
        ) {
            A component in your Evergreen//Foundation subscription is approaching the end of life
        }
    </div>
    <div class="days-reamaining-container">
        <div>in</div>
        <div class="days-remaining-num">{{ getEOLNumDays() }}</div>
        <div>Days</div>
    </div>

    <div
        class="below-icon-box controller"
        [ngClass]="{ 'human-icon': relatedRecommendations.leadingRecommendation.isSeRec() }"
    >
        <!-- TODO: update tooltip with CLOUD-96339 when we have an update on what to put here -->
        <pureui-svg
            [svg]="getSourceIcon()"
            height="13"
            [ngClass]="{ 'human-icon': relatedRecommendations.leadingRecommendation.isSeRec() }"
        ></pureui-svg>
        <!-- pureui-svg size is wonky, so add sized box for tooltip -->
        <div class="tooltip-box" [ngbTooltip]="getRecSourceTooltip()"></div>
    </div>
    <div *ngIf="relatedRecommendations.leadingRecommendation.hasControllerChange()" class="recommendation-message">
        {{ getControllerRecBlackText() }} <span class="simulation-text">{{ getControllerRecGreenText() }}</span>
    </div>
    <div *ngIf="shouldHaveCapacityRecommendationMessage()" class="recommendation-message">
        {{ getRecBlackText() }} <span class="simulation-text">{{ getRecGreenText() }}</span>
    </div>
    <div class="recommendation-message">
        @if (relatedRecommendations.getEoscRecommendation() != null) {
            Renew <span class="simulation-text">Support Contract</span>
        } @else if (relatedRecommendations.getForeverNowRecommendation() != null) {
            Upgrade to <span class="simulation-text">Evergreen//Forever</span>
        }
    </div>
</div>

<div *ngIf="relatedRecommendations.leadingRecommendation.isEoscRecommendation()" class="rec-card eosc-card">
    <div class="request-quote-tag">Request Quote</div>
    <button
        *ngIf="isDismissEnabled"
        type="button"
        class="close modal-x-button"
        (click)="clickDismiss($event)"
        ngbTooltip="Dismiss this recommendation"
    >
        <!-- pureui-svg size is wonky, so add sized box for tooltip -->
        <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="12"></pureui-svg>
    </button>
    <div class="icon-spacing">
        <pureui-svg svg="end-of-support-contract.svg" class="rec-symbol" height="24"></pureui-svg>
    </div>
    <div class="rec-array-name"> {{ getEntityName() }} </div>
    <div class="rec-explanation">The support contract for this appliance is expired</div>
    <div class="support-expired-container">
        <div>Support</div>
        <div class="big-expired-word">Expired</div>
    </div>

    <div
        class="below-icon-box controller"
        [ngClass]="{ 'human-icon': relatedRecommendations.leadingRecommendation.isSeRec() }"
    >
        <!-- TODO: update tooltip with CLOUD-96339 when we have an update on what to put here -->
        <pureui-svg
            [svg]="getSourceIcon()"
            height="13"
            [ngClass]="{ 'human-icon': relatedRecommendations.leadingRecommendation.isSeRec() }"
        ></pureui-svg>
        <!-- pureui-svg size is wonky, so add sized box for tooltip -->
        <div class="tooltip-box" [ngbTooltip]="getRecSourceTooltip()"></div>
    </div>
    <div class="recommendation-message">Renew <span class="simulation-text">Support Contract</span> </div>
    <div *ngIf="relatedRecommendations.getForeverNowRecommendation() != null" class="recommendation-message">
        Upgrade to <span class="simulation-text">Evergreen//Forever</span>
    </div>
</div>

<div *ngIf="relatedRecommendations.leadingRecommendation.isForeverNowRecommendation()" class="rec-card">
    <button
        *ngIf="isDismissEnabled"
        type="button"
        class="close modal-x-button"
        (click)="clickDismiss($event)"
        ngbTooltip="Dismiss this recommendation"
    >
        <!-- pureui-svg size is wonky, so add sized box for tooltip -->
        <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="12"></pureui-svg>
    </button>
    <div class="deal-badge">
        <pureui-svg class="pstg-action-icon" svg="discount-tag.svg" height="54"></pureui-svg>
    </div>
    <div class="icon-spacing">
        <pureui-svg svg="icon-controller-rec.svg" class="rec-symbol" height="24"></pureui-svg>
    </div>
    <div class="rec-array-name"> {{ getEntityName() }} </div>
    <div class="rec-explanation">
        Upgrade to Evergreen//Forever
        @if (relatedRecommendations.leadingRecommendation.getForeverNowDetails().region === 'OTHER') {
            with ForeverNOW for
        } @else {
            for a future-proof solution -
        }
        no end-of-life events, guaranteed controller refreshes, and non-disruptive modernization
    </div>
    <div class="icon-spacing lean-trade-up"
        ><pureui-svg svg="lean-trade-up.svg" class="rec-symbol" height="40"></pureui-svg
    ></div>
    <div
        *ngIf="
            relatedRecommendations.leadingRecommendation.getForeverNowDetails().region === 'OTHER' &&
            relatedRecommendations.leadingRecommendation.hasControllerChange()
        "
        class="recommendation-message"
    >
        Immediate upgrade {{ relatedRecommendations.leadingRecommendation.getForeverNowDetails().currentModel }} to
        <span class="simulation-text">{{
            relatedRecommendations.leadingRecommendation.getForeverNowDetails().recommendedModel
        }}</span>
    </div>
    <div class="recommendation-message">Upgrade to <span class="simulation-text">Evergreen//Forever</span></div>
</div>

<div *ngIf="relatedRecommendations.leadingRecommendation.isLeanSas2NvmeRecommendation()" class="rec-card trade-up">
    <button
        *ngIf="isDismissEnabled"
        type="button"
        class="close modal-x-button"
        (click)="clickDismiss($event)"
        ngbTooltip="Dismiss this recommendation"
    >
        <!-- pureui-svg size is wonky, so add sized box for tooltip -->
        <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="12"></pureui-svg>
    </button>
    <div class="deal-badge">
        <pureui-svg class="pstg-action-icon" svg="discount-tag.svg" height="54"></pureui-svg>
    </div>
    <div class="icon-spacing">
        <pureui-svg svg="icon-capacity-rec.svg" class="rec-symbol" height="24"></pureui-svg>
    </div>
    <div class="rec-array-name"> {{ getEntityName() }} </div>
    <div class="rec-explanation">{{ getLeanSas2NvmeMessage() }}</div>
    <div class="icon-spacing lean-trade-up"
        ><pureui-svg svg="lean-trade-up.svg" class="rec-symbol" height="40"></pureui-svg
    ></div>

    <div class="recommendation-message">
        {{ getRecBlackText() }} <span class="simulation-text">{{ getRecGreenText() }}</span>
    </div>
</div>

<div *ngIf="relatedRecommendations.leadingRecommendation.isEvergreenOneRecommendation()" class="rec-card">
    <button
        *ngIf="isDismissEnabled"
        type="button"
        class="close modal-x-button"
        (click)="clickDismiss($event)"
        ngbTooltip="Dismiss this recommendation"
    >
        <!-- pureui-svg size is wonky, so add sized box for tooltip -->
        <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="12"></pureui-svg>
    </button>
    <div class="icon-spacing">
        <pureui-svg svg="icon-evergreen-one-rec.svg" class="rec-symbol" height="24"></pureui-svg>
    </div>
    <div class="rec-array-name"> {{ getEntityName() }} </div>
    <div class="description">{{ getEOneProblem() }}</div>
    <div class="line-container">
        <recommendation-sparkline [data]="getSparkline()"></recommendation-sparkline>
        <div class="curr-percent" [style.top.px]="getCurrOffset()"> {{ getCurrentPercent() }} </div>
        <div class="threshold-percent" [style.top.px]="getProjectedOffset()">{{ getProjectedPercent() }}</div>
        <div class="vertical-now-line"></div>
        <div class="triangle-bottom"></div>
        <div class="now-container">
            <div class="now-text">Now</div>
        </div>
        <div class="in-days-container">
            <div class="in-days-text">{{ getTimerangeText() }}</div>
        </div>
    </div>

    <div class="below-icon-box" [ngClass]="{ 'human-icon': relatedRecommendations.leadingRecommendation.isSeRec() }">
        <pureui-svg
            [svg]="getSourceIcon()"
            height="13"
            [ngClass]="{ 'human-icon': relatedRecommendations.leadingRecommendation.isSeRec() }"
        ></pureui-svg>
        <!-- pureui-svg size is wonky, so add sized box for tooltip -->
        <div class="tooltip-box" [ngbTooltip]="getRecSourceTooltip()"></div>
    </div>
    <div class="recommendation-message">
        {{ getRecBlackText() }} <span class="simulation-text">{{ getRecGreenText() }}</span>
    </div>
</div>

<div *ngIf="relatedRecommendations.leadingRecommendation.isUnknownRecommendation()" class="rec-card">
    <div>Unknown Recommendation Type</div>
    <div>If you see this, please reach out to support.</div>
</div>
