import moment from 'moment';
import stableStringify from 'json-stable-stringify';
import isEqual from 'lodash/isEqual';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, filter, switchMap, take, tap, exhaustMap } from 'rxjs/operators';
import { OnInit, OnDestroy, Directive } from '@angular/core';
import {
    CollectionList,
    Resource,
    SortParams,
    FilterParams,
    ListParams,
    quoteAndEscape,
    sortOrder,
    DataPage,
} from '@pure1/data';

import {
    GLOBAL_FILTER,
    ARRAY_NAME_KEY,
    NAME_KEY,
    DEFAULT_NAMESPACE,
    FQDN,
    removePagination,
    addPagination,
    removeAllSorts,
    addSort,
    addFilter,
    removeFilters,
} from '../redux/actions';
import { createFilter, getFilterIdsByKey } from '../redux/utils';
import { UrlService } from '../redux/url.service';
import { UrlReaderWriter } from '../redux/url-reader-writer';
import { IStateFilter, IState } from '../redux/pure-redux.service';
import { NgRedux } from '../redux/ng-redux.service';
import { Action } from 'redux';
import { smartTimer } from '@pstg/smart-timer';

const PAGE_SIZE = 25;

const DEFAULT_SORT_KEY = 'name';

const DEFAULT_SORT_ORDER = 'asc';

export const DEFAULT_WILDCARD_FIELDS = [ARRAY_NAME_KEY, NAME_KEY, FQDN];

const SORT_REGEX = /^(.+?)(-)?$/;

type FilterOperator = 'equals' | 'contains' | 'tags';

export type FilterEntityKeyToValuesMap = { [entity: string]: { [key: string]: string } };

export interface IMultiValueFilter {
    namespace: string;
    entity: string;
    key: string;
    values: string[];
    operator: FilterOperator;
}

export function getOperator(key: string, namespace: string, wildcardFields: string[]): FilterOperator {
    // This should come from redux, since we know from /tag-key-summaries which fields are tags, wildcard, or exact_match
    if (namespace !== null) {
        return 'tags';
    } else if (wildcardFields.includes(key)) {
        return 'contains';
    } else {
        return 'equals';
    }
}

export function consolidateFilterValues(filters: IStateFilter[], wildcardFields: string[]): IMultiValueFilter[] {
    // group filter values by entity/key/namespace
    const multiValueFilterMap: { [entityKeyNamespace: string]: IMultiValueFilter } = {};
    filters.forEach(filter => {
        const entityKeyNamespace: string = filter.entity + filter.key + filter.namespace;
        if (multiValueFilterMap[entityKeyNamespace]) {
            multiValueFilterMap[entityKeyNamespace].values.push(filter.value);
        } else {
            multiValueFilterMap[entityKeyNamespace] = {
                entity: filter.entity,
                namespace: filter.namespace,
                key: filter.key,
                values: [filter.value],
                operator: getOperator(filter.key, filter.namespace, wildcardFields),
            };
        }
    });
    // return the multiValueFilters we have constructed in the single pass above
    return Object.keys(multiValueFilterMap).map(key => multiValueFilterMap[key]);
}

export function prepForDataLayer<T>(filters: IMultiValueFilter[]): FilterParams<T> {
    // for the time being, we have to format the query part ourselves
    // this should be done in the data layer
    const filterParams: FilterParams<T> = {};
    filters.forEach(filter => {
        // For now, only trim values for contains(). May want to change this to trim for all operators in the future.
        let values = filter.values;
        if (filter.operator === 'contains') {
            values = values.map(value => value.trim());
        }

        const valuesArr = values.map(value => `${quoteAndEscape(value)}`);
        const valuesStr = valuesArr.join(',');
        switch (filter.operator) {
            case 'contains':
                filterParams[filter.key] = `contains(${filter.key},(${valuesStr}))`;
                break;
            case 'tags':
                filterParams[filter.key] = `tags(${quoteAndEscape(filter.key)},(${valuesStr}))`;
                break;
            case 'equals':
            default:
                filterParams[filter.key] = `${filter.key}=(${valuesStr})`;
                break;
        }
    });
    return filterParams;
}

@Directive()
export class PagedDataComponent2<T extends Resource> implements OnInit, OnDestroy {
    // search
    filters: IMultiValueFilter[] = this.getDefaultFilter();
    tableHeaderFilters: FilterEntityKeyToValuesMap = {};
    wildcardFields: string[] = [];

    // sort
    sort: SortParams | null = null;
    defaultSortKey = DEFAULT_SORT_KEY;
    defaultSortOrder: sortOrder = DEFAULT_SORT_ORDER;
    // arrays use a different key for client-side sorting and rest calls
    defaultApiSortKey = DEFAULT_SORT_KEY;

    // pagination
    offset = 0;
    pageSize = PAGE_SIZE;

    // child class can use this to pass in their specific parameters for the .list() call
    // instead of the default properties used in this base class
    listCallParameters: ListParams<T> = null;

    /** Will be null before the first fetch completes, then should not be null again after */
    data: T[] | null = null;
    asOf: number | null = null;

    selected: Resource;
    total: number;
    subscription: Subscription;
    loading = false;

    private lastListCallParamsJson: string;
    private unsubscribeFromRedux: Function;
    private unsubscribeFromUrlService: Function;

    constructor(
        protected service: CollectionList<T>,
        protected ngRedux: NgRedux<IState>,
        private urlService: UrlService,
        protected urlReaderWriter: UrlReaderWriter,
        public barId: string,
        protected entity: string,
        protected namespace: string = DEFAULT_NAMESPACE,
        protected useGlobalFilters = true,
    ) {
        this.wildcardFields.push(...DEFAULT_WILDCARD_FIELDS);
    }

    ngOnInit(): void {
        if (this.urlReaderWriter) {
            this.urlReaderWriter.itemsPerPage = this.pageSize;
            this.unsubscribeFromUrlService = this.urlService.register(this.urlReaderWriter, true);
        }
        this.unsubscribeFromRedux = this.ngRedux.subscribe(() => this.onParamsChange());
        this.onParamsChange();
    }

    ngOnDestroy(): void {
        this.unsubscribe();

        if (this.unsubscribeFromRedux) {
            this.unsubscribeFromRedux();
        }
        if (this.unsubscribeFromUrlService) {
            this.unsubscribeFromUrlService();
        }
    }

    onSortChange(newSort: SortParams): void {
        this.ngRedux.dispatch([
            removeAllSorts(this.barId),
            removePagination(this.barId),
            addSort(this.barId, this.fromSort(newSort || { key: this.defaultSortKey, order: this.defaultSortOrder })),
        ]);
    }

    onPageChange(offset: number): void {
        this.ngRedux.dispatch([removePagination(this.barId), addPagination(this.barId, offset)]);
    }

    onItemsPerPageChange(itemsPerPage: number): void {
        this.pageSize = itemsPerPage;
        this.fetchData();
    }

    onSearchChange(key: string, value: string, entity = this.entity): void {
        const actions = this.getRemoveFilterActionsByKey(this.barId, key, entity, this.namespace);
        if (value !== '') {
            actions.push(addFilter(this.barId, createFilter(entity, this.namespace, key, value)));
        }
        // searching resets pagination
        actions.push(removePagination(this.barId));
        this.ngRedux.dispatch(actions);
    }

    onBeforeUpdateParams(): void {
        // this is intended to be left empty, the child class can override this.
    }

    onBeforeFetchData(): void {
        // this is intended to be left empty, the child class can override this.
    }

    onFetchDataDoesNotUpdateData(): void {
        // this is intended to be left empty, the child class can override this.
    }

    onAfterFetchFailed(_: Error): void {
        // this is intended to be left empty, the child class can override this.
    }

    onDataChange(data: T[]): void {
        // this is intended to be left empty, the child class can override this.
    }

    toggleSort(key: string): void {
        if (!!this.sort && key === this.sort.key) {
            this.onSortChange({ key, order: this.sort.order === 'asc' ? 'desc' : 'asc' });
        } else {
            this.onSortChange({ key, order: this.getDefaultSortForKey(key) });
        }
    }

    sortClass(key: string): string {
        if (this.sort === null) {
            return key === this.defaultSortKey ? 'st-sort-ascent' : '';
        } else if (this.sort.key === key) {
            return 'st-sort-' + (this.sort.order === 'asc' ? 'ascent' : 'descent');
        } else {
            return '';
        }
    }

    getTableHeaderFilter(entity: string, key: string): string {
        return this.tableHeaderFilters?.[entity]?.[key] || '';
    }

    protected fromSort(sort: SortParams): string {
        return `${sort.key}${sort.order === 'desc' ? '-' : ''}`;
    }

    protected toSort(sort?: string): SortParams | null {
        sort = (sort || '').trim();
        if (sort) {
            const [_, key, minus] = sort.match(SORT_REGEX);
            return { key, order: minus ? 'desc' : 'asc' };
        } else {
            return null;
        }
    }

    protected getDefaultSortForKey(_key: string): 'asc' | 'desc' {
        return this.defaultSortOrder || 'asc';
    }

    protected onParamsChange(paginationLocally = false): void {
        this.onBeforeUpdateParams();

        this.handleFilters();
        if (!paginationLocally) {
            this.offset = this.ngRedux.getState().paginations[this.barId] || 0;
        }

        const sorts = this.ngRedux.getState().sorts[this.barId];
        if (sorts && sorts.length > 0) {
            this.sort = this.toSort(sorts[0]);
        }
        if (!this.sort) {
            this.sort = { key: this.defaultApiSortKey, order: this.defaultSortOrder };
        }

        this.fetchData();
    }

    protected updateData(): void {
        this.fetchData(true);
    }

    protected getListParamsFields(): string[] | null {
        return null;
    }

    protected fetchData(forceRefresh = false): void {
        this.listCallParameters = {
            pageStart: this.offset,
            pageSize: this.pageSize,
            filter: prepForDataLayer(this.filters),
            sort: this.sort,
            defaultFilter: prepForDataLayer(this.getDefaultFilter()),
        };

        // do not populate this field unless we have fields, otherwise we will overwrite the default fields supplied
        // in pure1/data
        const fields = this.getListParamsFields();
        if (fields) {
            this.listCallParameters.fields = fields;
        }
        // last chance for derived components to pass filters via this.listCallParameters
        this.onBeforeFetchData();

        if (!this.shouldDoListCall(forceRefresh)) {
            this.onFetchDataDoesNotUpdateData();
            return;
        }

        this.unsubscribe();
        this.loading = true;
        this.lastListCallParamsJson = stableStringify(this.listCallParameters);

        const refreshInterval =
            this.getAutoRefreshInterval()?.asMilliseconds() ||
            this.service.refreshIntervalMs ||
            moment.duration(30, 'seconds').asMilliseconds();

        this.subscription = of(void 0)
            .pipe(
                tap(() => {
                    this.loading = true;
                }), // Only show the loading indicator on explicit updates (eg due to page load, or the query params changing). Don't show loading due to automatic background updates.
                switchMap(() => {
                    return smartTimer(0, refreshInterval) // Set up periodic updates
                        .pipe(
                            exhaustMap(() =>
                                this.callServiceList(this.listCallParameters) // Use exhaustMap() to ensure we don't keep killing requests if they consistently take longer than the refresh interval (and end up never finishing loading)
                                    .pipe(
                                        take(1),
                                        catchError(err => {
                                            console.warn('PagedData2 service.list() failed', err);
                                            this.onAfterFetchFailed(err);
                                            return of<DataPage<T>>(null);
                                        }),
                                    ),
                            ),
                        );
                }),
                filter(dataPage => dataPage != null), // Filter out null results caused by an error
                tap(() => {
                    this.loading = false;
                }), // Only hide loading indicator on success, that way failures to respond to user interactions (like changing sorting) don't look like they were successful
            )
            .subscribe(dataPage => {
                if (dataPage) {
                    this.data = dataPage.response || [];
                    this.total = dataPage.total || 0;
                    this.asOf = dataPage.asOf;
                } else {
                    this.data = [];
                    this.total = 0;
                    this.asOf = null;
                }

                // If the page requested is larger than the max number of pages, reload with the last page
                if (this.offset > this.total) {
                    this.onPageChange(Math.max(1, Math.ceil(this.total / this.pageSize)));
                }
                this.onDataChange(this.data);
                this.loading = false;
            });
    }

    /**
     * Makes the call to service.list() to fetch the new data.
     * Override to modify how the .list() call is made.
     */
    protected callServiceList(params?: ListParams<T>): Observable<DataPage<T>> {
        return this.service.list(params);
    }

    /**
     * Override to specify the auto-refresh interval to use for automatic background updates of the data.
     * If specified, this will take precedence over the interval specified by the service's refreshIntervalMs (which is a deprecated property).
     * If null, and the service does not specify refreshIntervalMs, a default of 30s will be used.
     * Changes to this value will only take effect when fetchData() is called and a new fetch is made, eg due to a change in filter params.
     */
    protected getAutoRefreshInterval(): moment.Duration | null {
        return null;
    }

    protected onFilterTextChange(barId: string, entity: string, key: string, values: string[]): void {
        const barFilters = this.ngRedux.getState().filters[barId];
        const ids = getFilterIdsByKey(barFilters, entity, key, DEFAULT_NAMESPACE);
        const actions: Action[] = [];
        if (ids.length) {
            actions.push(removeFilters(barId, ids));
        }
        if (values && values.length > 0) {
            values
                .filter(value => !!value) // make sure that it is not an empty string
                .forEach(value => {
                    actions.push(addFilter(barId, createFilter(entity, DEFAULT_NAMESPACE, key, value)));
                });
        }
        actions.push(removePagination(this.barId));
        this.ngRedux.dispatch(actions);
    }

    protected getDefaultFilter(): IMultiValueFilter[] {
        return [];
    }

    protected getDefaultFilterMap(): FilterEntityKeyToValuesMap {
        return {};
    }

    // Overrideable filter if you need to transform filter for API calls
    protected mapFilter(filter: IStateFilter): IStateFilter {
        return filter;
    }

    protected handleFilters(): void {
        // set this.filters and this.tableHeaderFilters based on current ngRedux state
        const localFilters: IStateFilter[] = this.ngRedux.getState().filters[this.barId] || [];

        const globalFilters: IStateFilter[] =
            (this.useGlobalFilters && this.ngRedux.getState().filters[GLOBAL_FILTER]) || [];
        const activeFilters = globalFilters.concat(localFilters).map(this.mapFilter);
        // Group filters by entity, and consolidate filters into groups of filter values
        this.filters = consolidateFilterValues(activeFilters, this.wildcardFields);
        // ignore pinned filters for table header filter text
        this.tableHeaderFilters = this.updateTableHeaderFilters(
            consolidateFilterValues(localFilters, this.wildcardFields),
        );
    }

    // this function should be called before we update this.sort in onParamsChange()
    protected isParamsChangeFromSorting(): boolean {
        const newSorts = this.ngRedux.getState().sorts[this.barId];
        return newSorts && !isEqual(this.sort, this.toSort(newSorts?.[0]));
    }

    // this function should be called before we update this.offset in onParamsChange()
    protected isParamsChangeFromPagination(): boolean {
        const newOffset = this.ngRedux.getState().paginations[this.barId];
        return newOffset != null && newOffset !== this.offset;
    }

    // Check if the parameters have changed. Otherwise, don't need to call .list() again.
    // One way this can happen is if we get notifications for redux events that don't affect this component's query params.
    protected shouldDoListCall(forceRefresh = false): boolean {
        const listCallParamsJson = stableStringify(this.listCallParameters);
        return this.lastListCallParamsJson !== listCallParamsJson || forceRefresh;
    }

    protected getRemoveFilterActionsByKey(barId: string, key: string, entity: string, namespace: string): Action[] {
        const actions: Action[] = [];
        const barFilters = this.ngRedux.getState().filters[barId];
        const ids = getFilterIdsByKey(barFilters, entity, key, namespace);
        if (ids.length) {
            actions.push(removeFilters(barId, ids));
        }
        return actions;
    }

    private updateTableHeaderFilters(filters: IMultiValueFilter[]): FilterEntityKeyToValuesMap {
        const filterMap: FilterEntityKeyToValuesMap = this.getDefaultFilterMap();
        filters.forEach((filter: IMultiValueFilter) => {
            if (filter.namespace === null) {
                // ignore tags from table header filters
                filterMap[filter.entity] = filterMap[filter.entity] || {};
                filterMap[filter.entity][filter.key] = filter.values.join(',');
            }
        });
        return filterMap;
    }

    private unsubscribe(): void {
        if (this.subscription && !this.subscription.closed) {
            this.subscription.unsubscribe();
        }
    }
}
